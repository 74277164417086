import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LibraryClient, LibraryModel } from '../../core/webapi';

@Injectable({ providedIn: 'root' })
export class LibraryService {
  library!: LibraryModel;

  constructor(private libraryClient: LibraryClient) {}

  init = async () => (this.library = await firstValueFrom(this.libraryClient.getLibrary()));
}
